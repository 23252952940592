import { navigate, withPrefix } from "gatsby"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import axios from "axios";
import { API_BASE } from "../../../spectory-config";
import Layout from "./Layout"
import Button from "../../../components/elements/Button"
import TextInput from "../../../components/elements/Inputs/TextInput";
import Checkbox from "../../../components/elements/Inputs/Checkbox";
import isLoggedIn from "../../../services/auth";
import { useDispatch, useSelector } from "react-redux";
import { closeNotification, setNotification, storeFormDataAction } from "../../../store/actions";
import LoadingSpinner from "../../../components/elements/LoadingSpinner";
import Link from "../../../components/elements/Link";


const Login = (props) => {

    const { register: registerLogin, handleSubmit: handleSubmitLogin, formState: formStateLogin } = useForm();
    const [loading, setLoading] = useState(false);
    const [loggedIn, setLoggedIn] = useState();
    const formData = useSelector(state => state?.checkout)
    const dispatch = useDispatch();

    useEffect(() => {
        isLoggedIn().then((res) => {

            setLoggedIn(res);

            if (res) {
                navigate('/kasse/billing')
            }

        })
    }, [])


    const submitLogin = async (data) => {

        dispatch(closeNotification());
        setLoading(true);
        var config = {
            method: 'post',
            url: `${API_BASE}/auth`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return axios(config)
            .then(function (response) {
                console.log(response)

                if (response.data.c === 200) {
                    if (data.stayLoggedIn) {
                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('userdata', JSON.stringify(response.data.userData));
                        sessionStorage.removeItem('token');
                        sessionStorage.removeItem('userdata');
                    } else {
                        sessionStorage.setItem('token', response.data.token);
                        sessionStorage.setItem('userdata', JSON.stringify(response.data.userData));
                        localStorage.removeItem('token');
                        localStorage.removeItem('userdata');
                    }

                    navigate(withPrefix('/kasse/billing'));

                } else {
                    dispatch(setNotification({
                        show: true,
                        type: 'error',
                        title: 'E-Mail oder Passwort sind falsch. Bitte überprüfen Sie Ihre Eingabe.'
                    }));
                    setLoading(false);
                }

            })
            .catch(function (error) {
                console.log(error);
                dispatch(setNotification({
                    show: true,
                    type: 'error',
                    title: 'E-Mail oder Passwort sind falsch. Bitte überprüfen Sie Ihre Eingabe.'
                }));
                setLoading(false);
            });
    }

    const withRegister = () => {
        let _data = formData;
        _data.register.withRegister = true;

        dispatch(storeFormDataAction(_data));

        navigate("/kasse/billing");
    }

    return (
        <Layout path={props.path}>

            <h1>Anmelden</h1>
            <br />
            <br />
            <form onSubmit={handleSubmitLogin(submitLogin)}>
                <TextInput
                    required
                    type="email"
                    label="E-Mail"
                    {...registerLogin('email')}
                />
                <TextInput
                    type="password"
                    label="Passwort"
                    required
                    {...registerLogin('password')}
                />
                <Checkbox
                    {...registerLogin('stayLoggedIn')}
                    label="Angemeldet bleiben"
                    style={{ marginTop: 20, marginBottom: 20 }}
                />

                <div className="container fluid" style={{ padding: 0 }}>
                    <div className="col5">
                        <div>
                            <Button
                                title="Login"
                                type="submit"
                                additionalStyle={{ width: '100%', marginBottom: 10 }}
                            />
                            <Link className="grey" to="/forgot-password">Passwort vergessen?</Link>
                        </div>
                    </div>
                    <div className="col2"></div>
                    <div className="col5">
                        <div>
                            <Button
                                title="Als Gast bestellen"
                                type="primary"
                                destination="/kasse/billing"
                                additionalStyle={{
                                    width: '100%', backgroundColor: '#ADADAD', borderColor: '#ADADAD', color: 'white', marginTop: 10
                                }}
                            />
                        </div>
                    </div>
                </div>


                <div className="container fluid" style={{ padding: 0, paddingTop: 50 }}>
                    <div className="col5">
                        <div>
                            <Button
                                title="Zurück zum Warenkorb"
                                type="secondary"
                                destination="/warenkorb"
                                additionalStyle={{
                                    width: '100%'
                                }}
                            />
                        </div>
                    </div>
                    <div className="col2"></div>
                    <div className="col5">
                        <div>
                            <Button
                                title="Weiter &amp; Konto erstellen"
                                type="secondary"
                                action={withRegister}
                                additionalStyle={{
                                    width: '100%'
                                }}
                            />
                        </div>
                    </div>
                </div>


            </form>

            <LoadingSpinner type="page" active={loading} />
        </Layout>
    )
}

export default Login;
